





























@import "../assets/scss/_variables.scss";
@import "../assets/scss/_stepper.scss";
.full-height{
  height: calc(100vh - 48px);
}
.not-page{
  &-image{
    max-width: 500px;
    margin-bottom: 24px;
    img{
      max-width: 100%;
    }
  }
}
