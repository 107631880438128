/*==========  Stepper Style  ==========*/

.stepper-container{
  .stepper-box{
    min-height: auto;
    background-color: rgba(1,1,1,0);
    box-shadow: none;
    .content{
      margin-bottom: 30px;
      overflow: unset;
    }
    .top{
      .divider-line{
        top: 6px;
        left: 0;
        height: 1px;
        background-color: #E6E6E6;
        border-color: #E6E6E6;
      }
      .steps-wrapper{
        padding: 0;
        width: 100%;
        .step{
          line-height: 0;
          text-align: left;
          align-items: flex-start;

          .circle{
            margin-bottom: 10px;
            background-color: inherit;
            padding: 0;
            i{
              padding: 0;
              width: 12px;
              height: 12px;
            }
          }
          .step-title h4{
            font-size: 14px;
            font-weight: 400;
          }
          &.deactivated{
            .circle i{
              background-color: #02BB9F!important;
            }
            .step-title h4{
              color: #02BB9F;
            }
          }
        }
        .activated.step ~ .step.deactivated{
          .circle i{
            background-color: rgb(228 228 230)!important;
          }
          .step-title h4{
            color: #AAAAAA;
          }
          //background-color: red;
        }
      }
    }
    .bottom{
      border: none;
      padding: 0;
      .stepper-button{
        &.next{
          background-color: #5E4BF1;
          border-radius: 6px;
          padding: 6px 12px;
          font-size: 14px;
          box-shadow: none;
          transition: all .34s ease-out;
          &.deactivated{
            background-color: #F0F0F0 !important;
            color: #AAAAAA;
            cursor: pointer!important;
          }
          i{
            display: none!important;
          }
        }
        &.previous{
          display: none;
        }
      }

      &.only-next{
        justify-content: flex-start;
      }
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .stepper-container .stepper-box .bottom .stepper-button.next:hover{
    background-color: #7161F3;
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




